import React from 'react';
import {Col, Container, Row} from "react-bootstrap";

function Timestamp({data}) {
    return (
        <div className="time_section p-100">
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={12} lg={10} xl={10} xxl={10} className="m-auto">
                        <h5 className="episode_name text-start">
                            Timestamps
                        </h5>
                        <ul className="timestamp">
                            {data?.timestamps_tabs?.map((value, i) => (
                                <li key={i}>
                                    <span>{value.time}</span>
                                    {value.text}
                                </li>
                            ))}
                        </ul>
                    </Col>
                </Row>

            </Container>
        </div>
    );
}

export default Timestamp;